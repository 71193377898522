<template>
  <div id="login">
    <div id="loginBox">
      <v-btn class="login_btn" large @click="checkIn('in')">上班打卡</v-btn>
      <v-btn class="login_btn" large @click="checkIn('out')">下班打卡</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    checkIn(type) {
      let params = {
        action: type,
      }
      this.$axios.post("https://mocking.space:10443/CenterService/sign", params).then(response => {
        //this.$axios.post("http://127.0.0.1:8061/sign", params).then(response => {
        if (response.data.code === '0') {
          this.$message.success("打卡成功！")
        } else {
          this.$message.error("打卡失败，失败信息：" + response.data.msg)
        }
      })
    },
  }
};
</script>

<style>
#login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 16px;
  color: #fff;
  font-family: "Source Sans Pro";
  background-image: url("../images/login.jpg");
  background-size: 100% 100%;
  position: relative;
}

#loginBox {
  width: 80%;
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 20px 30px 20px 30px;
  box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
  opacity: 1;
  background-image: url("../images/loginM.jpg");
  background-size: 100% 100%;
}

.login_btn {
  width: 100%;
  height: 30px;
  background: transparent; /*完全透明*/
  border: none;
  margin: 6px 10px;
  border-radius: 6px;
}


</style>

