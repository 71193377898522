import axios from 'axios';
import {Decrypt, Encrypt, getIV, getKey} from './utils.js'

axios.create({
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Request-ID': '',
  }
});

axios.defaults.baseURL = 'https://www.mocking.space:10443';
//axios.defaults.baseURL = 'http://127.0.0.1:9002';


axios.interceptors.request.use(config => {

  if (config.method === 'post') {
    let IV = getIV();
    const encryptData = Encrypt(JSON.stringify({...config.data}), IV);
    config.data = {};
    config.data.encryptData = encryptData;
    config.data.iv = IV;
  } else {
    config.params = {...config.params};
  }
  config.headers['X-Request-ID'] = getKey();
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';

  return config;
}, error => {  //请求错误处理
  return error;
});

axios.interceptors.response.use(
  res => {
    let result;
    switch (res.status) {
      case 404:
        console.log("404");
        break;
      case 500:
        console.log("服务器出错");
        break;
      case 401:
        console.log("401");
        break;
      case 200:
        var data = res.data;
        if (data.code === '0') {
          if (data.iv) {
            result = Decrypt(data.data, data.iv);
            res.data.data = result;
          }
        }
        break;
    }
    return res;
  },
  error => {
    return error;
  });

export default axios; //暴露axios实例
