import CryptoJS from 'crypto-js/crypto-js'
// 默认的 KEY 与 iv 如果没有给
const KEY = CryptoJS.enc.Utf8.parse("2020080810050511");
const IV = CryptoJS.enc.Utf8.parse('0911789161903810');

/**
 * AES加密 ：字符串 key iv  返回base64
 */
export function Encrypt(word, ivStr, keyStr) {
  let key = KEY
  let iv = IV

  if (keyStr && ivStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  } else if (ivStr) {
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  let srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

/**
 * AES 解密 ：字符串 key iv  返回base64
 */
export function Decrypt(word, ivStr, keyStr) {
  let key = KEY
  let iv = IV

  if (keyStr && ivStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr);
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  } else if (ivStr) {
    iv = CryptoJS.enc.Utf8.parse(ivStr);
  }

  let base64 = CryptoJS.enc.Base64.parse(word);
  let src = CryptoJS.enc.Base64.stringify(base64);

  var decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export function getKey() {
  const $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = $chars.length;
  let pwd = "";
  for (let i = 0; i < 32; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export function getIV() {
  const codeLength = 16;

  //设置随机字符
  const random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let code = '';

  for (let i = 0; i < codeLength; i++) {
    //设置随机数范围,这设置为0 ~ 36
    const index = Math.floor(Math.random() * 9);
    //字符串拼接 将每次随机的字符 进行拼接
    code += random[index];
  }
  return code.toString();
}
